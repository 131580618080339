import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO'

import s from './styles.module.css'

export default function Wholesale() {
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" data-full-line />
      </Helmet>
      <SEO
        title="Wholesale Account Sign Up Process | Chalk UK"
        description="Wholesale Sign Up Process. Become a stockist of Chalk's products."
      />
      <div className={s.container}>
        <div className={s.imgWrap}>
          <StaticImage
            src="../../assets/wholesale.jpg"
            alt="Wholesale"
            placeholder="NONE"
            backgroundColor="#eee"
          />
        </div>
        <div className={s.detailsWrapper}>
          <div className={s.spacing} />
          <div className={s.details}>
            <div className={s.header}>
              <h1 className={s.title}>Wholesale Accounts</h1>
              <a
                href="https://wholesale.chalkuk.com/account/login?checkout_url=/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.login}
              >
                Login
              </a>
              <span className={s.reset}>
                Reset your{' '}
                <a
                  href="https://wholesale.chalkuk.com/account/login?checkout_url=/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  login details
                </a>
              </span>
            </div>
            <div className={s.content}>
              <h2 className={s.title}>Want to become a stockist?</h2>
              <p className={s.description}>
                We have a constantly developing range of products, including
                accessories, clothing, homeware and home fragrances. Please
                follow the link below if you’d like to sell our products in your
                own shop or boutique.
              </p>
              <Link to="/wholesale/application" className={s.link}>
                Request Wholesale Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
